var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group"},[_c('editor',{attrs:{"api-key":"no-api-key","props":_vm.fieldOptions,"init":{
      height: 500,
      width: '100%',
      menubar: false,
      color_map: [
        '#66dac7',
        'Primary Green',
        '#656278',
        'Brown',
        '#F9EEE5',
        'Beige',
        '#ECCAFA',
        'Light Purple',
        '#C2E0F4',
        'Light Blue',

        '#2DC26B',
        'Green',
        '#F1C40F',
        'Yellow',
        '#E03E2D',
        'Red',
        '#B96AD9',
        'Purple',
        '#3598DB',
        'Blue',
        '#169179',

        '#000000',
        'Black',
        '#ffffff',
        'White',
      ],

      plugins: ['code', 'textcolor colorpicker', 'advlist autolink lists link image charmap preview anchor', 'media table', 'image'],
      toolbar:
        'undo redo | formatselect fontsizeselect bold italic underline | forecolor colorpicker backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | image media link | removeformat code',
      ..._vm.fieldOptions,
    }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }