var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"env-variables"},[_vm._l((_vm.internalValue),function(obj,index){return _c('div',{key:index,staticClass:"env-row row"},[(obj)?[_c('div',{staticClass:"form-group col-5 pr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(obj[0]),expression:"obj[0]"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(obj[0])},on:{"blur":function($event){return _vm.saveItem()},"input":function($event){if($event.target.composing)return;_vm.$set(obj, 0, $event.target.value)}}})]),_c('div',{staticClass:"form-group col-6 pr-0"},[_c('div',{staticClass:"input-group"},[_c('editor',{attrs:{"api-key":"no-api-key","props":_vm.fieldOptions,"init":{
              height: 500,
              width: '100%',
              menubar: false,
              color_map: [
                '#66dac7',
                'Primary Green',
                '#656278',
                'Brown',
                '#F9EEE5',
                'Beige',
                '#ECCAFA',
                'Light Purple',
                '#C2E0F4',
                'Light Blue',

                '#2DC26B',
                'Green',
                '#F1C40F',
                'Yellow',
                '#E03E2D',
                'Red',
                '#B96AD9',
                'Purple',
                '#3598DB',
                'Blue',
                '#169179',

                '#000000',
                'Black',
                '#ffffff',
                'White',
              ],

              plugins: ['code', 'textcolor colorpicker', 'advlist autolink lists link image charmap preview anchor', 'media table', 'image'],
              toolbar:
                'undo redo | formatselect fontsizeselect bold italic underline | forecolor colorpicker backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | image media link | removeformat code',
              ..._vm.fieldOptions,
            }},on:{"input":_vm.saveItem},model:{value:(obj[1]),callback:function ($$v) {_vm.$set(obj, 1, $$v)},expression:"obj[1]"}})],1)]),_c('div',{staticClass:"form-group col-1"},[_c('button',{staticClass:"btn btn-danger btn-sm btn-delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('i',{staticClass:"fa fa-times"})])])]:_vm._e()],2)}),_c('button',{staticClass:"float-right btn btn-secondary btn-sm json-textarea-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.addItem()}}},[_c('i',{staticClass:"fa fa-plus"})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }