<template>
  <div class="env-variables">
    <div v-for="(obj, index) in internalValue" class="env-row row" :key="index">
      <template v-if="obj">
        <div class="form-group col-5 pr-0">
          <input type="text" v-model="obj[0]" class="form-control" @blur="saveItem()" />
        </div>
        <div class="form-group col-6 pr-0">
          <div class="input-group">
            <editor
              api-key="no-api-key"
              :props="fieldOptions"
              v-model="obj[1]"
              @input="saveItem"
              :init="{
                height: 500,
                width: '100%',
                menubar: false,
                color_map: [
                  '#66dac7',
                  'Primary Green',
                  '#656278',
                  'Brown',
                  '#F9EEE5',
                  'Beige',
                  '#ECCAFA',
                  'Light Purple',
                  '#C2E0F4',
                  'Light Blue',

                  '#2DC26B',
                  'Green',
                  '#F1C40F',
                  'Yellow',
                  '#E03E2D',
                  'Red',
                  '#B96AD9',
                  'Purple',
                  '#3598DB',
                  'Blue',
                  '#169179',

                  '#000000',
                  'Black',
                  '#ffffff',
                  'White',
                ],

                plugins: ['code', 'textcolor colorpicker', 'advlist autolink lists link image charmap preview anchor', 'media table', 'image'],
                toolbar:
                  'undo redo | formatselect fontsizeselect bold italic underline | forecolor colorpicker backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | image media link | removeformat code',
                ...fieldOptions,
              }"
            />
          </div>
        </div>
        <div class="form-group col-1">
          <button type="button" class="btn btn-danger btn-sm btn-delete" @click="removeItem(index)">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </template>
    </div>
    <button type="button" class="float-right btn btn-secondary btn-sm json-textarea-button" @click="addItem()">
      <i class="fa fa-plus"></i>
    </button>
    <!-- <button type="button" class="btn btn-secondary btn-sm json-textarea-button" @click="saveItem()"><i class="fa fa-save"></i></button>
    -->
  </div>
</template>
<script>
import { abstractField } from 'vue-aw-components';
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/code';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/link';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/image';

import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/icons/default/';

import Editor from '@tinymce/tinymce-vue';

export default {
  name: 'FieldKeyEditorObject',
  mixins: [abstractField],
  components: { editor: Editor },
  data() {
    return {
      warning: '',
      internalValue: [],
    };
  },
  computed: {
    fieldOptions() {
      return this.schema && this.schema.fieldOptions ? this.schema.fieldOptions : {};
    },
  },
  watch: {
    // eslint-disable-next-line
    value(change, old) {
      this.internalValue = Object.entries(this.value);
    },
  },
  mounted() {
    if (this.value) {
      this.internalValue = Object.entries(this.value);
    }
  },

  beforeDestroy() {},
  methods: {
    addItem() {
      this.saveItem();
      this.$set(this.value, `NewKey_${Date.now()}`, '');
      this.$set(this.internalValue, `NewKey_${Date.now()}`, '');
      this.$forceUpdate();
    },

    removeItem(key) {
      this.$delete(this.value, this.internalValue[key][0]);
      this.$delete(this.internalValue, key);
      // this.saveItem();
      this.$forceUpdate();
    },

    saveItem() {
      console.log('SAVED!!!!');
      this.value = Object.fromEntries(this.internalValue);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~tinymce/skins/ui/oxide/skin.css';
@import '~tinymce/skins/ui/oxide/content.min.css';

.vue-form-generator .field-wrap .tox-editor-header button,
.vue-form-generator .field-wrap .tox-editor-header input[type='submit'] {
  border-radius: 0;
  border: initial;
  display: inherit;
  padding: initial;
}

.env-variables {
  width: 100%;
}
.env-row {
  position: relative;
}
.field-file-input {
  cursor: pointer;

  > * {
    cursor: pointer;
    width: 100%;
    height: auto;
  }
}

button.btn-delete {
  color: red !important;
  border: none;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
</style>
